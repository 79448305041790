import { CanActivateFn, Router } from '@angular/router'
import { inject } from '@angular/core'
import { UserService } from '@services/user'

export const authGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService)
  const router = inject(Router)

  if (!userService.checkAuth()) {
    return router.navigate(['/auth'])
  }

  return true
}
