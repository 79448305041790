import { HttpInterceptorFn } from '@angular/common/http'
import { environment } from '@env/environment'
import { catchError, throwError } from 'rxjs'
import { inject } from '@angular/core'
import { UserService } from '@services/user'

export const httpClientInterceptor: HttpInterceptorFn = (req, next) => {
  if (!environment.config.apiUrl) throw new Error('API URL is not defined')

  const userService = inject(UserService)

  const modifyUrl = req.url.startsWith('http') ? req.url : (environment.config.apiUrl + req.url)

  let headers = req.headers
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')

  if (req.headers.keys().indexOf('Authorization') === -1 && userService.getToken())
    headers = headers.set('Authorization', `Bearer ${ userService.getToken() }`)

  req = req.clone({
    url: modifyUrl,
    headers
  })

  return next(req).pipe(
    catchError((error) => {
      switch (error.status) {
        case 401:
          // TODO: Create AuthService and implement logout method
          console.warn('Need to redirect to 2FA verification token page')
          break
        default:
      }

      return throwError(() => error || { message: 'Unknown error' })
    })
  )
}
