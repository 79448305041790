import { isDevMode } from '@angular/core'
// NgRx
import { provideStoreDevtools } from '@ngrx/store-devtools'
// Stores
import { stores } from '@stores/stores.collection'
import { effects } from '@stores/effects.collection'
import { features } from '@stores/featuers.collection'

export const storeProviders = () => {
  const providers = [
    stores(),
    features(),
    effects(),
  ]

  if (isDevMode()) {
    providers.push(provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 50
    }))
  }

  return providers
}
