import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core'
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common'
import { provideRouter } from '@angular/router'
import { provideAnimations } from '@angular/platform-browser/animations'

import { storeProviders } from '@stores/provider'
import { routes } from './app.routes'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { httpClientInterceptor } from '@app/interceptors/http-client.interceptor'

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([
        httpClientInterceptor
      ])
    ),
    [
      {
        provide: DATE_PIPE_DEFAULT_OPTIONS,
        useValue: {
          dateFormat: 'd.MM.yyyy H:mm:ss'
        }
      }
    ],
    provideRouter(routes),
    storeProviders()
  ]
}

