import { Component, HostBinding, inject, ViewEncapsulation } from '@angular/core'
import { RouterOutlet } from '@angular/router'
// PrimeNG
import { PrimeNGConfig } from 'primeng/api'
import { Aura } from 'primeng/themes/aura'
import { UserService } from '@services/user'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  template: `<router-outlet/>`,
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  public config: PrimeNGConfig = inject(PrimeNGConfig)
  private readonly userService = inject(UserService)

  constructor () {
    this.config.theme.set({
      preset: Aura
    })

    this.userService.checkAuth()
  }
}
