import { Routes } from '@angular/router'
import { NotFoundComponent } from '@features/not-found'
import { authGuard } from '@app/guards/auth.guard'
import { authChildGuard } from '@app/guards/auth-child.guard'
import { nonAuthGuard } from '@app/guards/non-auth.guard'
import { nonAuthChildGuard } from '@app/guards/non-auth-child.guard'

export const routes: Routes = [
  {
    path: 'auth',
    canActivate: [nonAuthGuard],
    canActivateChild: [nonAuthChildGuard],
    loadComponent: () => import('@features/auth').then(m => m.AuthComponent)
  },
  {
    path: '',
    canActivate: [authGuard],
    canActivateChild: [authChildGuard],
    loadChildren: () => import('@features/dashboard').then(m => m.DashboardModule)
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full'
  }
]
