<div class="layout-wrapper">
  <strong>Header</strong>

  <div class="page-content">
    <h3>404 Not Found</h3>
    <p>Please check the URL and try again. If you think this is an error, please contact the administrator.</p>
    <a [routerLink]="''">Return to Home</a>
  </div>

  <strong>Footer</strong>
</div>
